<template>
  <div class="home">
    <a-menu mode="horizontal" :default-selected-keys="['/home']"
      style="background:rgba(255,255,255,0.7);position:fixed;top:0;height:80px;font-size:18px;padding:0;z-index:999"
      @menu-item-click="value => routerPush(value)">
      <a-menu-item disabled style="margin-right: 100px;">
        <div style="color:#333;font-size:30px;font-weight: 650;display:flex;flex-direction: column;justify-content: flex-end;height:100%;">
          <div>威梦科技</div>
          <div style="font-size: 18px;">Weimeng Technology</div>
        </div>
      </a-menu-item>
      <a-menu-item key="/home">
        <div style="width:4em;text-align:center">首页</div>
      </a-menu-item>
      <a-menu-item key="/show">电价展示</a-menu-item>
      <a-menu-item key="/emissionFactors">排放因子</a-menu-item>
      <a-menu-item key="/data-service">数据服务</a-menu-item>
      <a-menu-item key="/about">关于我们</a-menu-item>
      <a-menu-item disabled>
        <a-button shape="round" type="primary" style="margin-left:50px;padding:20px 35px" size="large" @click="goQuestion">立即咨询</a-button>
      </a-menu-item>
    </a-menu>
    <RouterView />
    <div class="footer">
      <div class="company-info">
        <div class="infomation">
          <div style="font-size: 18px;color:#333;font-weight: 650;">联系我们</div>
          <div>电话：19357573283</div>
          <div>邮箱：Sales@verdream.com.cn</div>
          <div>地址：浙江省杭州市滨江区长河街道月明路1040号3层35515室</div>
        </div>
        <div>
          <img :src="codeImg" style="width:100px;height: 100px" />
          <div>添加微信立即咨询</div>
        </div>
      </div>
      <a-divider />
      <div style="color:#aaa;font-size: 14px;padding: 0 120px 30px">
        Copyright © 2024 杭州威梦科技有限公司  版权所有 浙ICP备：00000000号
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import codeImg from '@/assets/code.png'

const active = ref('/home')
const router = useRouter()

const routerPush = (value)=>{
  router.push(value)
}

const goQuestion = ()=>{
  router.push('/login')
}
</script>

<style lang="less" scoped>
:deep(.arco-menu-item) {
  background: transparent !important;
  font-size: 1em;
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;

  .arco-menu-selected-label {
    bottom: 2px;
    background-color: rgb(29, 187, 129) !important;
  }

}

:deep(.arco-menu-inner) {
  padding: 0 !important;

  .arco-menu-overflow-wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.infomation{
  color: #555;
  font-size: 14px;
  &>div{
    margin: 10px 0;
  }
}
.footer{
  width: 1280px;
  margin: 20px auto 0;
  text-align: left;
}

.company-info{
  padding: 0 120px;
  display: flex;
  justify-content: space-between;
}

:deep(.arco-menu-selected) {
  color: rgb(29, 187, 129) !important;
}
</style>