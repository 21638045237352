import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  
  {
    path: "/",
    redirect: '/home',
    component: HomeView,
    children:[
      {
        path:"/home",
        name: 'home',
        component: ()=>import('../views/home')
      },
      {
        path:"/emissionFactors",
        name: 'emissionFactors',
        component: ()=>import('../views/emissionFactors')
      },
      {
        path:"/show",
        name: 'show',
        component: ()=>import('../views/show')
      },
      {
        path:"/data-service",
        name: 'dataService',
        component: ()=>import('../views/dataService')
      },
      {
        path:"/data-service-detail",
        name: 'dataServiceDetail',
        component: ()=>import('../views/dataService/detail.vue')
      },
      {
        path:"/about",
        name: 'about',
        component: ()=>import('../views/about')
      },
      {
        path:"/login",
        name: 'login',
        component: ()=>import('../views/login')
      },
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
